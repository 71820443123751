<template>
  <div class="home">
    <div
      class="primary d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar"
    >
      <v-row justify="space-around">
        <v-col cols="12" sm="12" md="1" lg="1">
          <span class="white--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="mt-2 mb-2">
          <span class="white--text"
            ><span class="white--text"
              ><v-icon color="#FFF" small>mdi-phone</v-icon> 81100 20091</span
            ></span
          >
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="2">
          <span class="white--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="1" lg="2">
          <span class="white--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="4" lg="3" class="mt-2 mb-2">
          <span class="white--text"
            ><v-icon color="#FFF" small>mdi-email</v-icon>
            kannancrackers2015@gmail.com</span
          >
        </v-col>
        <v-col cols="12" sm="12" md="1" lg="1">
          <span class="white--text"></span>
        </v-col>
      </v-row>
    </div>

    <v-app-bar color="white" fixed height="125px" class="mt-md-8 mt-lg-8">
      <v-container>
        <v-row
          justify="space-around"
          id="container"
          ref="container"
          class="mt-5"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            large
            class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <v-breadcrumbs :items="menus" divider=" ">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href">
                  <v-btn small color="secondary" class="mb-2">{{
                    item.text
                  }}</v-btn>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
          <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <v-breadcrumbs :items="menus"></v-breadcrumbs>
          </div>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="3"
            class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <img
              alt="Vue logo"
              src="../assets/logo.png"
              height="60"
              contain
              @click="home"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            class="d-flex justify-space-between flex-row text-wrap"
          >
            <v-btn icon v-if="cartQty" class="mb-8" @click="popupCheckout">
              <v-badge
                color="black"
                :content="cartQty"
                overlap
                offset-x="10"
                offset-y="10"
              >
                <v-icon color="black">mdi-cart-outline</v-icon>
              </v-badge>
            </v-btn>
            <v-btn icon v-if="cartQty == ''" class="mb-8">
              <v-badge
                color="black"
                content="0"
                overlap
                offset-x="10"
                offset-y="10"
                bordered
              >
                <v-icon color="black">mdi-cart-outline</v-icon>
              </v-badge>
            </v-btn>
            <v-btn text class="mb-8">
              <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                NET TOTAL :
              </div>
              ₹ {{ productSalesPrice }}
            </v-btn>
            <v-btn outlined color="primary" @click="contactDialog = true"
              ><v-icon color="primary" small class="mr-2">mdi-phone</v-icon
              >Contact</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        fab
        tile
        color="secondary"
        bottom
        right
        absolute
        v-if="cartQty"
        class="mr-n4 mb-n15 rounded-lg rounded-r-0"
        height="45"
        @click="popupCheckout"
      >
        <v-badge
          color="black"
          left
          :content="cartQty"
          overlap
          offset-x="0"
          offset-y="0"
        >
          <v-icon color="white" size="30">mdi-cart-variant</v-icon>
        </v-badge>
      </v-btn>

      <v-btn
        fab
        tile
        color="secondary"
        bottom
        right
        absolute
        v-if="cartQty == ''"
        class="mr-n4 mb-n15 rounded-lg rounded-r-0"
        height="45"
      >
        <v-badge
          color="black"
          left
          content="0"
          overlap
          offset-x="0"
          offset-y="0"
        >
          <v-icon color="white" size="30">mdi-cart-variant</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <v-dialog v-model="contactDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Contact Details
        </v-card-title>
        <!-- <v-card-text>
          <p class="mb-1 mt-5">
            <v-icon color="primary" small>mdi-phone</v-icon> Order Confirmation
            : 93843 57572, 80569 03599, 73971 53601, 97504 44600, 75388 97572
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Payment
            Confirmation: 97905 72926, 97900 39111, 97900 25111, 63854 27540,
            63854 27539
          </p>
          <p class="mb-1 mt-5">
            <v-icon color="primary" small>mdi-phone</v-icon> Dispatch:
            96003317037548840091,7540091520,9597631302, 9042744690
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Complaint:
            9942449196,8524844690,7530027560,7530034370
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Address
            Confirmation: 9655304041,8148251846
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Transport L L R
            confirmation : 7708367545,9597681301
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Owner Number :
            9384755471
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-email</v-icon>
            kannancrackers2015@gmail.com
          </p>
        </v-card-text> -->
        <v-card-text>
          <p class="mb-1 mt-5">
            <v-icon color="primary" small>mdi-phone</v-icon> Sivakasi : 81100 20091
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Coimbatore : 91596 99771, 91596 99772, 91596 99773
          </p>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="contactDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <div class="productdiv" ref="productdiv1">
        <marquee-text
          :duration="50"
          :repeat="1"
          class="mt-5 red--text text--darken-4"
        >
          <h3 v-html="banner.scroll_content"></h3>
        </marquee-text>

        <v-card outlined color="primary" dark class="mx-auto">
          <!--<h4 class="text-center pa-1 white--text">Crackers Available 365 Days</h4>
          <h2 class="text-center pa-1 yellow--text text--accent-4 big-text"> Minimum Order 1000 Rupees Only</h2>
          <h2 class="text-center pa-1 white--text">2023-Diwali  Order Closed Date- 12-0ctober</h2>
          <h2 class="text-center pa-1 white--text">5000 For Free Delivery</h2>
          <h2 class="text-center pa-1 yellow--text text--accent-4">Office Time 9 To 9 </h2>-->
          <div
            v-html="banner.details"
            class="text-center white--text banner-text"
          ></div>
        </v-card>
      </div>

      <div class="mt-5 text-right">
        <v-btn color="secondary" outlined @click="priceListOpen"
          >Download Price List</v-btn
        >
      </div>

      <v-expansion-panels v-model="panel" multiple class="mt-5">
        <v-expansion-panel v-for="(category, i) in products" :key="i">
          <v-expansion-panel-header class="text-center">
            <h3 class="text-decoration-underline red--text text--darken-4">
              {{ category.categoryname }}
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-for="(product, i) in category.products"
                :key="i"
              >
                <v-card class="mx-auto" outlined>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row justify="space-around">
                        <v-col cols="12" sm="9" md="9">
                          <v-list-item-subtitle
                            class="text--primary font-weight-bold font-label"
                          >
                            <v-row justify="space-around">
                              <v-col cols="12" sm="4" md="4">
                                <h3
                                  class="text-wrap mt-5"
                                  style="max-width: 25rem; color: #2e7d32"
                                >
                                  {{ product.name }}
                                </h3>
                                <h3
                                  class="text-wrap mt-2"
                                  style="max-width: 25rem; color: #d500f9"
                                >
                                  {{ product.tamil_name }}
                                </h3>
                              </v-col>
                              <v-col cols="12" sm="8" md="8">
                                <div
                                  class="
                                    d-flex
                                    justify-space-between
                                    flex-row
                                    text-wrap
                                    mt-5
                                  "
                                >
                                  <h3 style="color: #b71c1c">
                                    {{ product.content }}
                                  </h3>
                                  <h3 style="color: #2e7d32">
                                    Rs. {{ product.rate }}
                                  </h3>
                                  <h3
                                    class="text-decoration-line-through"
                                    style="color: #b71c1c"
                                  >
                                    Rs. {{ product.offerPrice }}
                                  </h3>
                                  <h3 style="color: #2e7d32">
                                    Rs. {{ product.salesprice }}
                                  </h3>
                                </div>
                              </v-col>
                            </v-row>
                          </v-list-item-subtitle>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <div class="qty-alt rounded-xl">
                            <div class="d-flex qty-btn">
                              <v-btn
                                class="ma-1"
                                x-small
                                outlined
                                fab
                                color="black"
                                @click="decrement(product)"
                              >
                                <v-icon outlined fab size="15"
                                  >mdi-minus</v-icon
                                >
                              </v-btn>
                              <v-text-field
                                outlined
                                v-model="product.qty"
                                dense
                                class="mt-1 qty-textbox"
                                @change="productQtyUpdate(product)"
                                type="number"
                              ></v-text-field>
                              <v-btn
                                class="ma-1"
                                x-small
                                outlined
                                fab
                                color="black"
                                @click="increment(product)"
                              >
                                <v-icon outlined fab size="15">mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <div class="justify-space-between text-center mt-2">
                            <h3 style="color: #d500f9">
                              Rs.{{ product.totalSalesPrice }}
                            </h3>
                          </div>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                    <!-- <v-list-item-avatar size="80" tile>
                                            <v-img :src="product.image" @click="imageDialogOpen(product.image)"></v-img>
                                        </v-list-item-avatar> -->

                    <div
                      class="
                        d-flex d-sm-flex d-md-flex d-lg-flex
                        align-center align-self-center
                      "
                    >
                      <v-list-item-avatar
                        size="80"
                        tile
                        class="align-center align-self-center"
                      >
                        <v-img
                          :src="product.image"
                          @click="imageDialogOpen(product.image)"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-icon
                        class="align-center align-self-center"
                        @click="youtubeOpen(product.youtube_url)"
                        v-if="product.youtube_url"
                      >
                        <v-icon color="red" size="70"> mdi-youtube </v-icon>
                      </v-list-item-icon>
                    </div>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <v-dialog v-model="imageDialog" width="500">
      <v-card>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="imageDialog = false" color="primary">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-img :src="clickedImage"></v-img>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="590">
      <v-card>
        <v-card-title class="text-h5"> </v-card-title>
        <v-card-text>
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in cartError.response" :key="i">
              <h3 v-html="error"></h3>
            </div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="errorDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="checkoutDialog"
      persistent
      transition="dialog-top-transition"
      width="900"
    >
      <v-card>
        <v-card-title class="text-h5 red darken-2 white--text">
          Checkout
        </v-card-title>

        <v-card-text class="mt-5">
          <v-list three-line>
            <template>
              <div
                v-for="(category, i) in products"
                :key="i"
                class="product-cart-div"
              >
                <div
                  v-for="(product, i) in category.products"
                  :key="i"
                  class="product-cart-div"
                >
                  <v-list-item v-if="product.qty > 0">
                    <v-card outlined class="mx-auto product-cart-card">
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-list-item-subtitle
                              class="text--primary font-weight-bold font-label"
                            >
                              <v-row>
                                <v-col cols="12" sm="4" md="4">
                                  <h3
                                    class="text-wrap ml-2"
                                    style="max-width: 25rem; color: #b71c1c"
                                  >
                                    {{ product.name }}
                                  </h3>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                  <div
                                    class="
                                      d-flex
                                      justify-space-between
                                      flex-row
                                      text-wrap
                                      ml-2
                                      mr-2
                                    "
                                  >
                                    <div>
                                      <h3 style="color: #2e7d32">
                                        Rs. {{ product.salesprice }}
                                      </h3>
                                    </div>

                                    <!-- <h3>Qty. {{ product.qty }} </h3> -->
                                    <div
                                      class="
                                        cart-qty-alt
                                        rounded-xl
                                        d-none d-md-block d-lg-block d-xl-block
                                      "
                                    >
                                      <div class="qty-btn d-flex">
                                        <v-btn
                                          class="ma-1 mt-2 ml-5"
                                          x-small
                                          outlined
                                          fab
                                          color="black"
                                          @click="decrement(product)"
                                        >
                                          <v-icon outlined fab size="15"
                                            >mdi-minus</v-icon
                                          >
                                        </v-btn>
                                        <v-text-field
                                          outlined
                                          v-model="product.qty"
                                          dense
                                          class="mt-1 cart-qty-textbox"
                                          @change="productQtyUpdate(product)"
                                          type="number"
                                        ></v-text-field>
                                        <v-btn
                                          class="ma-1 mt-2"
                                          x-small
                                          outlined
                                          fab
                                          color="black"
                                          @click="increment(product)"
                                        >
                                          <v-icon outlined fab size="15"
                                            >mdi-plus</v-icon
                                          >
                                        </v-btn>
                                      </div>
                                    </div>

                                    <div
                                      class="
                                        mobile-cart-qty-alt
                                        rounded-xl
                                        d-block d-md-none d-lg-none d-xl-none
                                      "
                                    >
                                      <div class="qty-btn d-flex">
                                        <v-btn
                                          class="ma-1 mt-2"
                                          x-small
                                          outlined
                                          fab
                                          color="black"
                                          @click="decrement(product)"
                                        >
                                          <v-icon outlined fab size="15"
                                            >mdi-minus</v-icon
                                          >
                                        </v-btn>
                                        <v-text-field
                                          outlined
                                          v-model="product.qty"
                                          dense
                                          class="mt-1 cart-qty-textbox"
                                          @change="productQtyUpdate(product)"
                                          type="number"
                                        ></v-text-field>
                                        <v-btn
                                          class="ma-1 mt-2"
                                          x-small
                                          outlined
                                          fab
                                          color="black"
                                          @click="increment(product)"
                                        >
                                          <v-icon outlined fab size="15"
                                            >mdi-plus</v-icon
                                          >
                                        </v-btn>
                                      </div>
                                    </div>

                                    <div>
                                      <h3 style="color: #d500f9">
                                        Rs. {{ product.totalSalesPrice }}
                                      </h3>
                                    </div>

                                    <!--<v-btn color="secondary" outlined x-small fab v-show="products.length > 1"
                                      @click="productDelete(k)">
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>-->
                                  </div>
                                </v-col>
                              </v-row>
                            </v-list-item-subtitle>
                          </v-col>

                          <!--<v-col cols="12" sm="2" md="2">
                            <v-list-item-avatar size="80" tile>
                              <v-img :src="product.image"></v-img>
                            </v-list-item-avatar>
                          </v-col>-->
                        </v-row>
                      </v-list-item-content>
                    </v-card>
                  </v-list-item>
                </div>
              </div>
            </template>
          </v-list>
        </v-card-text>

        <v-card-text>
          <!--alert box-->
          <!-- <v-alert text prominent type="error" v-if="errorResponse">
              <div v-for="(error, i) in cartError.response" :key="i">
                <h3 v-html="error"></h3> 
              </div>
            </v-alert>-->
          <!--alert box end-->

          <!--alert box-->
          <v-alert
            text
            prominent
            type="success"
            v-if="successResponse"
            class="mt-4"
          >
            <div>
              {{ cartError }}
              <v-btn
                outlined
                @click="refereshPage()"
                color="primary"
                class="ml-15"
                >close</v-btn
              >
            </div>
          </v-alert>
          <!--alert box end-->

          <!--select Product start-->
          <!--<div v-for="(category, i) in products" :key="i">
              <v-row v-for="(product, i) in category.products" :key="i">
                <v-col cols="12" sm="12" md="12" v-if="product.qty > 0">
                  <v-card class="mx-auto" outlined>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row justify="space-around">
                          <v-col cols="12" sm="9" md="9">
                            <v-list-item-subtitle class="text--primary font-weight-bold font-label">
                              <v-row justify="space-around">
                                <v-col cols="12" sm="4" md="4">
                                  <h3 class="text-wrap mt-5" style="max-width: 25rem;">{{ product.name }} </h3>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                  <div class="d-flex justify-space-between flex-row text-wrap mt-5">
                                    <h3>Rs. {{ product.rate }}</h3>
                                    <h3 class="text-decoration-line-through">Rs. {{ product.offerPrice }}</h3>
                                    <h3>Rs. {{ product.salesprice }}</h3>
                                  </div>
                                </v-col>
                              </v-row>
  
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <div class="qty-alt rounded-xl">
                              <v-btn class="ma-1" x-small outlined fab color="black" @click="decrement(product)">
                                <v-icon outlined fab size="15">mdi-minus</v-icon>
                              </v-btn>
                              {{ product.qty }}
                              <v-btn class="ma-1" x-small outlined fab color="black" @click="increment(product)">
                                <v-icon outlined fab size="15">mdi-plus</v-icon>
                              </v-btn>
  
                            </div>
                            <div class="justify-space-between text-center mt-2">
                              <h3>Rs.{{ product.totalSalesPrice }}</h3>
                            </div>
  
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                      <v-list-item-avatar size="80" tile>
                        <v-img :src="product.image" @click="imageDialogOpen(product.image)"></v-img>
                      </v-list-item-avatar>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </div>-->
          <!--selected Product End-->

          <form class="mt-5" v-if="!successResponse">
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Name"
              required
              outlined
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="email"
              :error-messages="emailErrors"
              label="E-mail"
              required
              outlined
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="mobileno"
              :error-messages="mobilenoErrors"
              label="Mobileno"
              required
              outlined
              @input="$v.mobileno.$touch()"
              @blur="$v.mobileno.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="state"
              :error-messages="stateErrors"
              label="State"
              required
              outlined
              @input="$v.state.$touch()"
              @blur="$v.state.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="deliverycity"
              :error-messages="deliverycityErrors"
              label="Delivery City"
              required
              outlined
              @input="$v.deliverycity.$touch()"
              @blur="$v.deliverycity.$touch()"
            ></v-text-field>
            <v-textarea
              v-model="address"
              label="Address"
              required
              outlined
              @input="$v.address.$touch()"
              @blur="$v.address.$touch()"
              :error-messages="addressErrors"
            ></v-textarea>

            <v-row justify="space-around" id="container" ref="container">
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="productRate"
                  outlined
                  label="Rate"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="productOfferPrice"
                  outlined
                  label="Offer Price"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  v-model="productSalesPrice"
                  outlined
                  label="Sales Price"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              label="Order Confirm?"
              required
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox>
            <v-divider></v-divider>
            <v-btn
              class="mr-4 mt-5"
              @click="submitCheckout"
              outlined
              :disabled="chechoutbtn"
            >
              submit
            </v-btn>
            <v-btn
              @click="checkoutDialog = false"
              color="primary"
              outlined
              class="mr-4 mt-5"
            >
              close
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <loading v-if="loading" />

    <v-container>
      <v-card class="mx-auto" outlined>
        <v-card-text>
          <!--alert box-->
          <v-alert
            text
            prominent
            type="success"
            v-if="successResponse"
            class="mt-4"
          >
            <div>
              {{ cartError }}
              <v-btn
                outlined
                @click="refereshPage()"
                color="primary"
                class="ml-15"
                >close</v-btn
              >
            </div>
          </v-alert>
          <!--alert box end-->

          <form class="mt-5" v-if="!successResponse">
            <v-row justify="space-around" id="container" ref="container">
              <v-col cols="12" sm="6" md="6">
                <v-row justify="space-around" id="container" ref="container">
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="name"
                      :error-messages="nameErrors"
                      label="Name"
                      required
                      outlined
                      @input="$v.name.$touch()"
                      @blur="$v.name.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="email"
                      :error-messages="emailErrors"
                      label="E-mail"
                      required
                      outlined
                      @input="$v.email.$touch()"
                      @blur="$v.email.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="mobileno"
                      :error-messages="mobilenoErrors"
                      label="Mobileno"
                      required
                      outlined
                      @input="$v.mobileno.$touch()"
                      @blur="$v.mobileno.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="6">
                    <v-text-field
                      v-model="state"
                      :error-messages="stateErrors"
                      label="State"
                      required
                      outlined
                      @input="$v.state.$touch()"
                      @blur="$v.state.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="6">
                    <v-text-field
                      v-model="deliverycity"
                      :error-messages="deliverycityErrors"
                      label="Delivery City"
                      required
                      outlined
                      @input="$v.deliverycity.$touch()"
                      @blur="$v.deliverycity.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-textarea
                  v-model="address"
                  label="Address"
                  required
                  outlined
                  @input="$v.address.$touch()"
                  @blur="$v.address.$touch()"
                  :error-messages="addressErrors"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-row justify="space-around" id="container" ref="container">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="productRate"
                      outlined
                      label="Rate"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="productOfferPrice"
                      outlined
                      label="Offer Price"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="productSalesPrice"
                      outlined
                      label="Sales Price"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-checkbox
              v-model="checkbox"
              :error-messages="checkboxErrors"
              label="Order Confirm?"
              required
              @change="$v.checkbox.$touch()"
              @blur="$v.checkbox.$touch()"
            ></v-checkbox>
            <v-divider></v-divider>
            <v-btn
              class="mr-4 mt-5"
              @click="submitCheckout"
              outlined
              :disabled="chechoutbtn"
            >
              submit
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-container>

    <footerCommon />
  </div>
</template>

<style scoped>
.top-bar {
  position: fixed;
  width: 100%;
  z-index: 111;
}

.v-application p {
  margin-bottom: 0px;
}

.qty-alt {
  border: 1px solid #ddd;
  width: 100%;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.qty-btn {
  margin-left: 5% !important;
  margin-right: 5% !important;
}

.qty-textbox {
  max-width: 55%;
  text-align: center !important;
}

.qty-textbox .v-text-field {
  text-align: center !important;
}

.productdiv {
  margin-top: 165px !important;
}

.banner-text {
  line-height: 50px;
}

.footer-card {
  width: 100%;
}

.product-cart-div {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.product-cart-card {
  width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.cart-qty-alt {
  border: 1px solid #ddd;
  width: 45%;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.cart-qty-alt .qty-btn {
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.cart-qty-textbox {
  max-width: 50%;
  text-align: center !important;
}

.cart-qty-textbox .v-text-field {
  text-align: center !important;
}

.mobile-cart-qty-alt {
  border: 1px solid #ddd;
  width: 100%;
  text-align: center;
  font-weight: 500;
  height: 50px;
}

.mobile-cart-qty-alt .qty-btn {
  margin-left: 0% !important;
  margin-right: 0% !important;
}

/*
  .productdiv::before {
      content: "";
      position: absolute;
      top: 24%;
      left: 6%;
      transform: skew(20deg) translateX(-72%);
      width: 73%;
      height: 20%;
      background-color: #ffd600;
       opacity: .7;
      z-index: 9999;
  }
  .productdiv::after {
      content: "";
      position: absolute;
      top: 24%;
      right: 6%;
      transform: skew(20deg) translateX(-72%);
      width: 73%;
      height: 20%;
      background-color: #ffd600;
       opacity: .7;
  }*/
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import { required, email } from "vuelidate/lib/validators";
//import store from "../store";

import MarqueeText from "vue-marquee-text-component";
import router from "../router";

import { VUE_WEB_BASEURL } from "../utils/api";
export default {
  name: "Home",
  data: () => ({
    panel: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27,
    ],
    productRate: "0.00",
    productOfferPrice: "0.00",
    productSalesPrice: "0.00",
    cartQty: 0,
    checkoutDialog: false,
    name: "",
    email: "",
    mobileno: "",
    state: "",
    deliverycity: "",
    address: "",
    checkbox: false,
    chechoutbtn: false,
    errorDialog: false,
    contactDialog: false,
    imageDialog: false,
    clickedImage: "",
    menus: [
      {
        text: "Retail Price",
        disabled: false,
        href: "/",
      },
      {
        text: "Family pack",
        disabled: false,
        href: "familypack",
      },
      {
        text: "Wholesale case",
        disabled: false,
        href: "wholesale",
      },
      {
        text: "Whole sale",
        disabled: false,
        href: "diwali",
      },
      {
        text: "Gift box",
        disabled: false,
        href: "#",
      },
      {
        text: "About Us",
        disabled: false,
        href: "aboutus",
      },
      {
        text: "Contact Us",
        disabled: false,
        href: "contactus",
      },
    ],
  }),
  validations: {
    name: { required },
    email: { required, email },
    mobileno: { required },
    state: { required },
    deliverycity: { required },
    address: { required },

    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  computed: {
    ...mapGetters([
      "authStatus",
      "authStatusError",
      "authStatusresponse",
      "getProducts",
      "getBannerDetails",
      "getOrderStatus",
      "orderStatusResponse",
    ]),
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    mobilenoErrors() {
      const errors = [];
      if (!this.$v.mobileno.$dirty) return errors;
      !this.$v.mobileno.required && errors.push("Mobileno is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    deliverycityErrors() {
      const errors = [];
      if (!this.$v.deliverycity.$dirty) return errors;
      !this.$v.deliverycity.required &&
        errors.push("Delivery City is required.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required.");
      return errors;
    },

    loading: function () {
      return this.authStatus === "loading";
    },
    products: function () {
      return this.getProducts;
    },
    banner: function () {
      return this.getBannerDetails;
    },

    errorResponse: function () {
      // console.log(this.getOrderStatus);
      return this.getOrderStatus === "error";
    },
    successResponse: function () {
      //console.log(this.getOrderStatus);
      return this.getOrderStatus === "success";
    },
    cartError: function () {
      return this.orderStatusResponse;
    },
  },
  created() {
    this.fetchGiftboxProducts();
    this.fetchGiftboxBannerDetails();
    setTimeout(() => {
      this.allPanels();
    }, 2500);

    this.$store.subscribe((mutation) => {
      //console.log(mutation);
      if (mutation.type === "authError") {
        this.errorDialog = true;
      }

      if (mutation.type === "authRequest") {
        this.chechoutbtn = true;
      }

      if (
        mutation.type === "orderConfirmed" ||
        mutation.type === "orderConfirmedError"
      ) {
        this.chechoutbtn = false;
      }
    });
  },
  methods: {
    ...mapActions([
      "fetchGiftboxProducts",
      "fetchGiftboxBannerDetails",
      "giftboxOrderConfirm",
    ]),
    allPanels() {
      this.panel = [...Array(this.products.length).keys()].map((k, i) => i);
      //console.log(this.products.length);
    },
    increment(product) {
      // store.commit("authRequest");
      product.qty = product.qty + 1;
      product.totalRate = product.qty * product.rate;
      product.totalOfferPrice = product.qty * product.offerPrice;
      product.totalSalesPrice = product.qty * product.salesprice;
      this.calculateTotal();
      this.chechoutbtn = false;
    },
    decrement(product) {
      //  store.commit("authRequest");
      product.qty = product.qty === 0 ? 0 : product.qty - 1;
      product.totalRate = product.qty * product.rate;
      product.totalOfferPrice = product.qty * product.offerPrice;
      product.totalSalesPrice = product.qty * product.salesprice;
      this.calculateTotal();
      this.chechoutbtn = false;
    },
    productQtyUpdate(product) {
      //console.log(product);
      // console.log(event.target.value);
      // store.commit("authRequest");
      if (event.target.value) {
        product.qty = product.qty < 0 ? 0 : parseInt(event.target.value);
        product.totalRate = product.qty * product.rate;
        product.totalOfferPrice = product.qty * product.offerPrice;
        product.totalSalesPrice = product.qty * product.salesprice;
        this.calculateTotal();
      } else {
        product.qty = 0;
        product.totalRate = product.qty * product.rate;
        product.totalOfferPrice = product.qty * product.offerPrice;
        product.totalSalesPrice = product.qty * product.salesprice;
        this.calculateTotal();
      }
    },
    calculateTotal() {
      var totalQtyCalc;
      totalQtyCalc = this.products.reduce(function (categorysum, product) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var qty = parseFloat(product.qty);
          if (!isNaN(qty)) {
            return productsum + qty;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      }, 0);
      this.cartQty = totalQtyCalc;
      // console.log(totalQtyCalc);
      var totalRateCalc;
      totalRateCalc = this.products.reduce(function (categorysum, product) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalRate = parseFloat(product.totalRate);
          if (!isNaN(totalRate)) {
            return productsum + totalRate;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      }, 0);

      //console.log(totalRateCalc);

      this.productRate = totalRateCalc.toFixed(2);

      var totalOfferPriceCalc;
      totalOfferPriceCalc = this.products.reduce(function (
        categorysum,
        product
      ) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalOfferPrice = parseFloat(product.totalOfferPrice);
          if (!isNaN(totalOfferPrice)) {
            return productsum + totalOfferPrice;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      },
      0);

      //console.log(totalOfferPriceCalc);
      this.productOfferPrice = totalOfferPriceCalc.toFixed(2);

      var totalSalesPriceCalc;
      totalSalesPriceCalc = this.products.reduce(function (
        categorysum,
        product
      ) {
        var catTotal = product.products.reduce(function (productsum, product) {
          var totalSalesPrice = parseFloat(product.totalSalesPrice);
          if (!isNaN(totalSalesPrice)) {
            return productsum + totalSalesPrice;
          }
        }, 0);
        if (!isNaN(catTotal)) {
          return categorysum + catTotal;
        }
      },
      0);

      // console.log(totalSalesPriceCalc);

      this.productSalesPrice = totalSalesPriceCalc.toFixed(2);
    },
    refereshPage() {
      window.location.reload();
    },
    popupCheckout() {
      this.checkoutDialog = true;
    },
    submitCheckout() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.chechoutbtn = true;
        let orderDetails = [];
        this.products.forEach(function (productlist) {
          productlist.products.forEach(function (product) {
            // console.log(product);
            if (parseFloat(product.qty) > 0) {
              orderDetails.push(product);
            }
          });
        });
        setTimeout(() => {
          this.chechoutbtn = false;
        }, 2000);

        // do your submit logic here
        setTimeout(() => {
          const orderData = {
            name: this.name,
            email: this.email,
            mobileno: this.mobileno,
            state: this.state,
            deliverycity: this.deliverycity,
            address: this.address,
            productRate: this.productRate,
            productOfferPrice: this.productOfferPrice,
            productSalesPrice: this.productSalesPrice,
            cartQty: this.cartQty,
            orderDetails: orderDetails,
          };
          //console.log(orderData);
          this.giftboxOrderConfirm(orderData);
          //this.chechoutbtn=false;
        }, 500);
      }
    },
    imageDialogOpen(image) {
      console.log(image);
      this.clickedImage = image;
      this.imageDialog = true;
    },
    youtubeOpen(url) {
      console.log(url);
      window.open(url, "_blank");
    },
    home() {
      router.push("/");
    },
    priceListOpen() {
      window.open(VUE_WEB_BASEURL + "giftbox-product/", "_blank");
    },
  },
  components: {
    MarqueeText,
  },
};
</script>
